import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedLoanPrograms from "../components/Repeating/RelatedLoanPrograms";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import lowerFixedInterestRate from "../images/2.0 Loan Programs/USDA/box-1.svg";
import noDownPayment from "../images/2.0 Loan Programs/USDA/box.svg";
import flexibleCreditGuidelines from "../images/2.0 Loan Programs/USDA/ic_outline-auto-graph.svg";
import closingCostsFinancedIntoLoan from "../images/2.0 Loan Programs/USDA/box-2.svg";

export const query = graphql`
  query CityUSDATemplateQuery($id: String!) {
    loanProgram: sanityCityLoanPrograms(id: { eq: $id }) {
      id
      city {
        title
      }
      seoTitle
      metaDescription
      loanProgram
    }
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/USDA Loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/USDA Loans.jpg" }
    ) {
      publicURL
    }
  }
`;

const Template = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-usda-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-usda-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-usda-loans/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question: "What is the maximum amount I can borrow?",
      answer: (
        <>
          <p className="mb-0">
            USDA loans do not have any restrictions on the amount you can
            borrow. The loan amount you qualify for is based on your debt,
            income, and ability to repay.
          </p>
        </>
      ),
    },
    {
      question: "Would I need to pay Private Mortgage Insurance (PMI)?",
      answer: (
        <>
          <p className="mb-0">
            No, USDA loans do not require borrowers to pay private mortgage
            insurance. There is, however, a one-time upfront USDA guarantee fee
            that is 1% of the loan amount. The upfront fee can be financed into
            your loan, so you don’t have to pay it out of pocket. Additionally,
            you would pay an annual 0.35% USDA fee. The annual fee would be
            rolled into your monthly payments.
          </p>
        </>
      ),
    },
    {
      question: "Are both 15-year and 30-year loans available?",
      answer: (
        <>
          <p className="mb-0">
            Yes. You can opt for the traditional 30-year loan or choose a
            15-year loan to build equity faster and take a shorter path to full
            homeownership.
          </p>
        </>
      ),
    },
    {
      question: `What type of home can I buy in ${data.loanProgram.city.title} with a USDA loan?`,
      answer: (
        <>
          <p>
            You can purchase the following types of homes, as long as the one
            you choose is intended to be your primary residence and it meets
            USDA’s requirements that ensure it’s safe to live in and sanitary.
          </p>
          <ul className="styled-list-checkmark">
            <li>Single-family homes</li>
            <li>New construction</li>
            <li>Manufactured homes</li>
            <li>Condominiums</li>
            <li>Townhouses</li>
            <li>Short sale homes</li>
            <li>Foreclosed homes</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title={data.loanProgram.seoTitle}
        description={data.loanProgram.metaDescription}
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Loan Programs/USDA/1.0 USDA - hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>{data.loanProgram.city.title} USDA Home Loan</h1>
              <p>
                Do you feel more at home surrounded by open spaces than miles of
                pavement? But are you limited by income and worried you wouldn’t
                qualify to purchase a home in a rural community in the{" "}
                {data.loanProgram.city.title}
                area? You might be able to benefit from one of the government’s
                least-known mortgage assistance programs, a USDA home loan.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to USDA Home Loans</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Who it’s For"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Eligibility"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Who It’s For</h3>
                <p className="mb-0">
                  A USDA home loan program offers no down payment, low-interest
                  rate mortgages for those with income at or below the low
                  income level in the rural community where they wish to live.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">
                    The Benefits for {data.loanProgram.city.title}-Area Home
                    Buyers
                  </h3>
                </header>

                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={noDownPayment}
                      alt="No Down Payment"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">No Down Payment</h3>
                    <p className="mb-0">
                      USDA loans don’t require a down payment, one of the
                      biggest barriers for hopeful home buyers.
                    </p>
                  </div>

                  <div>
                    <img
                      src={flexibleCreditGuidelines}
                      alt="Flexible Credit Guidelines"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Flexible Credit Guidelines</h3>
                    <p className="mb-0">
                      The minimum credit score you’ll need is 640, although
                      getting approved with a lower credit score is possible.
                    </p>
                  </div>

                  <div>
                    <img
                      src={lowerFixedInterestRate}
                      alt="Lower Fixed Interest Rate"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Lower Fixed Interest Rate</h3>
                    <p className="mb-0">
                      USDA home loans, backed by a government guarantee, offer
                      below-market interest rates.
                    </p>
                  </div>

                  <div>
                    <img
                      src={closingCostsFinancedIntoLoan}
                      alt="Closing Costs Financed Into the Loan"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">
                      Closing Costs Financed Into the Loan
                    </h3>
                    <p className="mb-0">
                      You can take out a bigger loan amount to pay for closing
                      costs with a USDA home loan.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Eligibility Requirements</h3>
                <ul className="styled-list-checkmark">
                  <li>Property must be located in an eligible rural area</li>
                  <li>
                    Borrower must be a U.S. citizen, non-citizen national, or
                    qualified alien
                  </li>
                  <li>Borrower must meet low income requirements</li>
                  <li>Borrower must live in the home as a primary residence</li>
                  <li>
                    Borrower demonstrates the ability to meet credit obligations
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <RelatedLoanPrograms
        heading="Other Loan Programs"
        hiddenProgram={4}
        location={data.loanProgram.city.title}
      />
      <CallToAction />
    </Layout>
  );
};

export default Template;
